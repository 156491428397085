import { DataTable } from "primereact/datatable"
import { IColumn } from "../../../../../../contracts/IColumn"
import { Column } from "primereact/column"
import dateFormat from "dateformat"
import { Button, Tooltip } from "@mui/material"
import * as XLSX from "xlsx";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import "./OpenTicketStatusDetail.css";


const OpenTicketStatusDetail = (props) => {
  const { data } = props

  const columns: IColumn[] = [
    {
      id: "RequestName",
      label: "Design Request Name",
      minWidth: 350,
      visible: true,
      sortable: true,
      resizeable: true
    },
    {
      id: "RevisionName",
      label: "Design Revision Name",
      minWidth: 350,
      visible: true,
      sortable: true,
      resizeable: true
    },
    {
      id: "OriginalQueue",
      label: "Original Queue",
      minWidth: 350,
      visible: true,
      sortable: true,
      resizeable: true
    },
    {
      id: "Status",
      label: "Status",
      minWidth: 350,
      visible: true,
      sortable: true,
      resizeable: true
    },
    {
      id: "UserFullName",
      label: "Request: Owner Name",
      minWidth: 350,
      visible: true,
      sortable: true,
      resizeable: true
    },
    {
      id: "PauseReason",
      label: "Pause Reason",
      minWidth: 350,
      visible: true,
      sortable: true,
      resizeable: true
    },
    {
      id: "HandoverReason",
      label: "Handover Reason",
      minWidth: 350,
      visible: true,
      sortable: true,
      resizeable: true
    },
    {
      id: "RescheduleReason",
      label: "Reschedule Reason",
      minWidth: 350,
      visible: true,
      sortable: true,
      resizeable: true
    },
    {
      id: "OrderDate",
      label: "OrderDate",
      defaultFormat: (value) => { 
        return dateFormat(value.OrderDate, "dd-mm-yyyy") 
      },
      minWidth: 350,
      visible: true,
      sortable: true,
      resizeable: true
    },
    {
      id: "DueDate",
      label: "Due Date",
      defaultFormat: (value) => { 
        return dateFormat(value.DueDate, "dd-mm-yyyy") 
      },
      minWidth: 350,
      visible: true,
      sortable: true,
      resizeable: true
    },
    {
      id: "CreatedDate",
      label: "Created Date",
      defaultFormat: (value) => { 
        return dateFormat(value.CreatedDate, "dd-mm-yyyy") 
      },
      minWidth: 350,
      visible: true,
      sortable: true,
      resizeable: true
    },
    {
      id: "AppointmentScheduleDate",
      label: "Appointment Date",
      defaultFormat: (value) => { 
        return dateFormat(value.AppointmentScheduleDate, "dd-mm-yyyy") 
      },
      minWidth: 350,
      visible: true,
      sortable: true,
      resizeable: true
    },
    {
      id: "Locale",
      label: "Locale",
      minWidth: 350,
      visible: true,
      sortable: true,
      resizeable: true
    },
    {
      id: "Type",
      label: "Type",
      minWidth: 350,
      visible: true,
      sortable: true,
      resizeable: true
    },
    {
      id: "AgeHours",
      label: "Hours Before Deadline",
      minWidth: 350,
      visible: true,
      sortable: true,
      resizeable: true
    }
  ]

  const exportToExcel = () => {
      var fileName = 'OpenTicketStatusFile';

      const ws = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, ws, "Employee Report");
      XLSX.writeFile(workbook, fileName + ".xlsx");
  }

  return (
    <>
      <div className="uploadbutn common-iconbutton">
        <Tooltip title={"Export to Excel"} placement="top" arrow>
            <Button onClick={() => exportToExcel()}>
              <FileUploadOutlinedIcon />
            </Button>
          </Tooltip>
      </div>
      <div className="ticketdatatable">
        <DataTable 
          value={data}
          paginator 
          size="small"
          rows={10} 
          rowsPerPageOptions={[10, 25, 50, 100, 200]}
          resizableColumns 
          showGridlines 
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
        >
            {columns.map((column, index) => {
              return (
                <Column
                  key={index}
                  sortable={column.sortable !== undefined ? column.sortable : false}
                  resizeable={
                    column.resizeable !== undefined ? column.resizeable : false
                  }
                  field={column.id}
                  header={column.label}
                  body={column.defaultFormat}
                ></Column>
              );
            })}
        </DataTable>
      </div>
    </>
  )
}

export default OpenTicketStatusDetail