import { useEffect, useState } from 'react'
import { Box, TextField, Tooltip } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LoadingButton } from '@mui/lab';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import { Dropdown } from "primereact";
import dateFormat from "dateformat";

import Breadcrumbs from "../../../../../components/Breadcrumb/Breadcrumb";
import "./HeadCountPlannerReportsPage.css";
import { useHCPService } from '../../../services/HCPService';
import { useCommonService } from '../../../../../services/CommonService';
import { oldExportToExcelHistoricalFieldsArr, newExportToExcelHistoricalFieldsArr } from '../../../constants/HCPConstant';
import { bindReportFields, exportToCSV } from '../../../services/helper';

const allBreadCrumbs = [
  {
    title: "P & F",
    link: "",
  },

  {
    title: "HeadCountPlanner",
    link: "/hcp/headcountplanner",
  },

  {
    title: "Reports",
    link: "",
  },
]

const HeadCountPlannerReportsPage = () => {
  const hcpService = useHCPService();
  const commonService = useCommonService();
  
  const date = new Date();
  const [startDate, setStartDate] = useState<Date | null>(date);
  const [endDate, setEndDate] = useState<Date | null>(date);
  const [weeksData, setWeeksData] = useState<any>([]);
  const [selectedOption, setSelectedOption] = useState(null);
  
  const [downloadWeekBtnLoader, setDownloadWeekBtnLoader] = useState(false);
  const [downloadDateRangeBtnLoader, setDownloadDateRangeBtnLoader] = useState(false);
  
  const fetchWeeks = () => {
    let reqObj = {};
    hcpService.getWeeks(reqObj).then((res) => {
      var result = res.data;
      if (result.isSuccess) {
        const groupedData = result.versionWeeks.reduce((acc, week) => {
          const { year, versionWeek } = week;
          if (!acc[year]) {
            acc[year] = [];
          }
          acc[year].push({ name: versionWeek, value: versionWeek });
          return acc;
        }, {});

        const dropdownData = Object.keys(groupedData).map(year => ({
          label: year,
          items: groupedData[year]
        }));
        setWeeksData(dropdownData);
      } else
        setWeeksData([]);
    })
      .catch((err) => {
        commonService.showToast("error", err);
      });
  };
  
  const exportToExcelHistoricalData = (data) => {
    var date = new Date();
    var curDate = dateFormat(date, "yyyy_mm_dd");
    var fileName = "HeadCountHistoricalReport_" + curDate;
    var masterDataExport: any = [];
    var oldFieldArray: any = [],
      newFieldArray: any = [];

    oldFieldArray = oldExportToExcelHistoricalFieldsArr;
    newFieldArray = newExportToExcelHistoricalFieldsArr;

    masterDataExport = bindReportFields(data, oldFieldArray, newFieldArray);
    if (masterDataExport.length > 0)
      exportToCSV(masterDataExport, fileName);
  };
  
  const fetchHCPHistoricalDataPerWeek = () => {
    setDownloadWeekBtnLoader(true);
    if (selectedOption == null) {
      setDownloadWeekBtnLoader(false);
      commonService.showToast("error", "Please select the Week ");
      return;
    }
    else {
      const reqObj = {
        VersionWeek: selectedOption,
      };
      hcpService.getheadcounthistoricaldetails(reqObj).then((res) => {
        var result = res.data;
        if (result.isSuccess) {
          exportToExcelHistoricalData(result.historicalInfos);
        }
        setDownloadWeekBtnLoader(false);
      })
        .catch((err) => {
          setDownloadWeekBtnLoader(false);
          commonService.showToast("error", err);
        });
    }
  };
  
  const fetchHCPHistoricalDataPerDateRange = () => {
    setDownloadDateRangeBtnLoader(true);

    const reqObj = {
      startDate,
      endDate,
      filterValues: "",
    };

    hcpService.getHeadCountDetail(reqObj).then((res) => {
      var result = res.data;
      if (result.isSuccess) {
        exportToExcelHistoricalData(result.users);
      }
      setDownloadDateRangeBtnLoader(false);
    })
      .catch((err) => {
        setDownloadDateRangeBtnLoader(false);
        commonService.showToast("error", err);
      });
  };
    
  useEffect(() => {
    fetchWeeks()
  }, [])
  
  return (
    <>
      <Box className="bread-crums-box">
        <Breadcrumbs allBreadCrumbs={allBreadCrumbs} />
      </Box>
      <Box className="white-board">
        <Box
          className="select-user-box cf"
          sx={{ textAlign: "left", marginBottom: "10px" }}
        >
          <Box className="start-end-date-content">
            <Box className="dataselect-box">
              <div style={{width: "70%", margin: "0 auto 30px"}}>
                <h4>Historical Download by date range</h4>
                <p>
                  Select Date range that you would like to pull the historical data
                  from HeadCount Planner and click on "Download" button.
                </p>
              </div>
            </Box>
            <Box className="ddcal">
              <Box className="start-end-date-selection">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Start Date"
                    value={startDate}
                    inputFormat="MM/dd/yyyy"
                    onChange={(e) => setStartDate(e)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Box>
              <Box className="start-end-date-selection" sx={{marginRight:"0 !important"}}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="End Date"
                    value={endDate}
                    inputFormat="MM/dd/yyyy"
                    minDate={startDate}
                    onChange={(e) => setEndDate(e)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Box>              
            </Box>
            <Box className="common-iconbutton historicaldownloadbutns">
                <Tooltip title={"Download"} placement="top" arrow>
                  <LoadingButton
                    variant="contained"
                    loading={downloadDateRangeBtnLoader}
                    sx={{
                      py: 1,
                      px: 3,
                      fontSize: "15px",
                      letterSpacing: "0.05em",
                    }}                  
                    onClick={() => fetchHCPHistoricalDataPerDateRange()}
                  >
                    <DownloadRoundedIcon />
                  </LoadingButton>
                </Tooltip>
            </Box>
          </Box>

          <Box className="start-end-date-content" sx={{borderRight: "none !important"}}>
            <Box className="dataselect-box">
              
              <div style={{width: "70%", margin: "0 auto 30px"}}>
                <h4>Historical Download by week</h4>
                <p>
                  Select the week that you would like to pull the historical data
                  from HeadCount Planner and click on "Download" button.
                </p>
              </div>
            </Box>
            <Box className="ddcal">
              <Dropdown
                options={weeksData}
                placeholder="Historical Download"
                optionLabel="name"
                filter
                value={selectedOption}
                optionGroupLabel="label"
                optionGroupChildren="items"
                onChange={(e) => setSelectedOption(e.value)} required />            
            </Box>
            <Box className="common-iconbutton historicaldownloadbutns">
                <Tooltip title={"Download"} placement="top" arrow>
                  <LoadingButton
                    variant="contained"
                    loading={downloadWeekBtnLoader}
                    sx={{
                      py: 1,
                      px: 3,
                      fontSize: "15px",
                      letterSpacing: "0.05em",
                    }}                  
                    onClick={() => fetchHCPHistoricalDataPerWeek()}
                  >
                    <DownloadRoundedIcon />
                  </LoadingButton>
                </Tooltip>
            </Box>
          </Box>          
        </Box>
      </Box> 
    </>
  )
}

export default HeadCountPlannerReportsPage