import AgentMarket from "../components/presentations/MarketWidget/AgentMarket";
import AgentStatus from "../components/presentations/AgentStatusWidget/AgentStatus";
import AgentStatusList from "../components/presentations/AgentStatusWidget/AgentStatusList";
import FilterOption from "../components/presentations/AgentStatusWidget/FilterOption";
import MarketList from "../components/presentations/MarketWidget/MarketList";
import AgentStatusSummary from "../components/presentations/AgentStatusWidget/AgentStatusSummary";
import PieChart from "../components/presentations/Charts/PieChart";
import EmailChatFilterOption from "../components/presentations/ChatEmailMarket/EmailChatFilterOption";
import ChatStatusAnalysis from "../components/presentations/ChatEmailMarket/ChatAnalysis";
import ChatStatusList from "../components/presentations/ChatEmailMarket/ChatStatusList";
import ChatCaseAgeList from "../components/presentations/ChatEmailMarket/ChatCaseAgeList";
import { Privileges } from "../../../constants/CommonConstant";
import VisitorChatStatsList from "../components/presentations/ChatEmailMarket/VisitorChatStatsList";
import MarketCard from "../components/presentations/MarketWidget/MarketCard";
import ChatAnalysisCard from "../components/presentations/ChatEmailMarket/ChatAnalysisCard";
import EmailAgeCard from "../components/presentations/ChatEmailMarket/EmailAgeCard";
import ChatAgeCard from "../components/presentations/ChatEmailMarket/ChatAgeCard";
import OpenTicketStatus from "../components/presentations/CareDesign/OpenTicketStatus";
import OpenTicketList from "../components/presentations/CareDesign/OpenTicketList";
import CareDesignFilter from "../components/presentations/CareDesign/CareDesignFilter";
import EmailAnalysis from "../components/presentations/ChatEmailMarket/EmailAnalysis";
import EmailAnalysisList from "../components/presentations/ChatEmailMarket/EmailAnalysisList";
import IntervalList from "../components/presentations/CareDesign/IntervalList";

export const RTDConstants = {
  Connected: "CONNECTED",
  OnHold: "ONHOLD",
  Ended: "ENDED",
  Ready: "READY",
  Phone: "Phone",
  Chat: "Chat",
  Outbound: "Outbound",
  ChatAndEmail: "Chat and Email",
  ChatWrapup: "Chat Wrapup",
  Email: "Email",
  DIFYRequests: 'DIFY Requests',
  DesignLive:'Design Live',
  DesignerCheckIn: 'Designer Check-IN',
  CallBack: 'Call Back',
  CaseManager: 'Case Manager', 
  JRDAPTicket:'JRDAP Ticket',
  PPAGCase:'PPAG Case', 
  SpecialTask: 'Special Task',
  QA:'QA',
  WOCC:'Working On Customer Case'
};

export const StatusColorConstants = [
  { Status: "ENDED", Color: "#FF3C3C",Type: 'ContactState' },
  { Status: "ONHOLD", Color: "#8EA9DB",Type: 'ContactState' },
  { Status: "CONNECTED", Color: "#4caf50" ,Type: 'ContactState'},
  { Status: "CONNECTING", Color: "#64ffda" ,Type: 'ContactState'},
  { Status: "MISSED", Color: "#FF4B4B",Type: 'ContactState' },
  { Status: "EMPTY", Color: "#81c784",Type: 'ContactState' },
  { Status: "Callback", Color: "#8FD050" },
  { Status: "Chat", Color: "#9EFF96" },
  { Status: "Email", Color: "#42FF33" },
  { Status: "Chat_and_Email", Color: "#0EC400" },
  { Status: "Outbound", Color: "#9999FF" },
  { Status: "WOCC", Color: "#FF0066" },
  { Status: "Meeting_Admin", Color: "#00B0F0" },
  { Status: "Coaching", Color: "#D90066" },
  { Status: "Lunch", Color: "#FFE100" },
  { Status: "Special_Task", Color: "#BDD7EE" },
  { Status: "Training", Color: "#0072C0" },
  { Status: "Break", Color: "#76ff03" },
  { Status: "Personal", Color: "#FFD966" },
  { Status: "System_Issues", Color: "#f44336" },
  { Status: "QA", Color: "#337EC3" },
  { Status: "Chat_Wrapup", Color: "#D37F91" },
  { Status: "READY", Color: "#81c784" },
];

export const FilterModuleTypeConstants = {
  AgentStatus: 1,
  Market: 2,
  CareDesign: 3,
  AgentStaffing: 4
};

export const WidgetSteps = {
  SelectWidget: "Select widget",
  SelectView: "Select view",
  Configure: "Configure",
  Filters: "Filters",
};

export const WidgetViewItemConstants = {
  AgentStatusView: "agentstatusview",
  AgentStatusSummaryView: "agentstatusummaryview",
  MarketAnalysisView: "marketanalysisview",
  // EmailQueueAnalysisView: "emailqueueanalysisview",
  // EmailAgeAnalysisView: "emailageanalysisview",
  ChatAnalysisView: "chatanalysisview",
  ChatAgeAnalysisView: "chatageanalysisview",
  VisitorChatStatView: "visitorchatstatview",
  OpenTicketStatusView: 'openticketstatusview',
  EmailAnalysisView: 'emailanalysisview'
};

export const WidgetViewItems = [
  {id:WidgetViewItemConstants.AgentStatusView,label:'Agent Status', widgetId: FilterModuleTypeConstants.AgentStatus},
  {id:WidgetViewItemConstants.AgentStatusSummaryView,label:'Agent Status Summary', widgetId: FilterModuleTypeConstants.AgentStatus},
  // {id:WidgetViewItemConstants.EmailQueueAnalysisView,label:'Email Status Analysis',widgetId: FilterModuleTypeConstants.Market},
  // {id:WidgetViewItemConstants.EmailAgeAnalysisView,label:'Email Age Analysis',widgetId: FilterModuleTypeConstants.Market},
  {id:WidgetViewItemConstants.ChatAnalysisView,label:'Chat Analysis',widgetId: FilterModuleTypeConstants.Market},
  {id:WidgetViewItemConstants.ChatAgeAnalysisView,label:'Chat Age Analysis',widgetId: FilterModuleTypeConstants.Market},
  {id:WidgetViewItemConstants.MarketAnalysisView,label:'Phone Analysis',widgetId: FilterModuleTypeConstants.Market},
  {id:WidgetViewItemConstants.VisitorChatStatView,label:'Visitor Chat Stat',widgetId: FilterModuleTypeConstants.Market},
  {id:WidgetViewItemConstants.EmailAnalysisView,label:'Email Analysis',widgetId: FilterModuleTypeConstants.Market},
  {id:WidgetViewItemConstants.OpenTicketStatusView,label:'Open Ticket Status',widgetId: FilterModuleTypeConstants.CareDesign}
]

export const compareValues = (value1, operator: string, value2) => {
  value1 = typeof(value1) === 'string' ? value1.toLowerCase() : value1
  value2 = typeof(value2) === 'string' ? value2.toLowerCase() : value2
  
  switch (operator) {
    case 'equals':
      return value1 === value2
    case 'notequals':
      return value1 !== value2
    case 'lessthan':
      return value1 < value2
    case 'lessthanorequalto':
      return value1 <= value2
    case 'greaterthan':
      return value1 > value2
    case 'greaterthanorequalto':
      return value1 >= value2
    case 'startwith':
      return value1?.startsWith(value2)
    case 'endwith':
      return value1?.endsWith(value2)
    case 'contains':
      return value1?.includes(value2)
    case 'notContains':
      return !value1?.includes(value2)
    default:
      return false
  }
}

export const LogicalOprators = [
  {'value': 'and', 'label' : 'AND'},
  {'value': 'or', 'label' : 'OR'}
]

export const FilterTypeOperators = [
  {
    id:'string', 
    label: 'String', 
    operators: [{'id': 'startwith', 'label' : 'Starts with'},
      {'id': 'endwith', 'label' : 'Ends with'},
      {'id': 'equals', 'label' : 'Equals'},
      {'id': 'notequals', 'label' : 'Not equals'},
      {'id': 'contains', 'label' : 'Contains'},
      {'id': 'notContains', 'label' : 'Not contains'}
    ]
  },
  {
    id:'number', 
    label: 'Number', 
    operators: [
      {'id': 'equals', 'label' : 'Equals'},
      {'id': 'notequals', 'label' : 'Not equals'},
      {'id': 'lessthan', 'label' : 'Less than'},
      {'id': 'lessthanorequalto', 'label' : 'Less than or equal to'},
      {'id': 'greaterthan', 'label' : 'Greater than'},
      {'id': 'greaterthanorequalto', 'label' : 'Greater than or equal to'}
    ]
  },
  {
    id:'time', 
    label: 'Time', 
    operators: [
      {'id': 'equals', 'label' : 'Equals'},
      {'id': 'notequals', 'label' : 'Not equals'},
      {'id': 'lessthan', 'label' : 'Less than'},
      {'id': 'lessthanorequalto', 'label' : 'Less than or equal to'},
      {'id': 'greaterthan', 'label' : 'Greater than'},
      {'id': 'greaterthanorequalto', 'label' : 'Greater than or equal to'}
    ]
  },
  {
    id:'dropdown', 
    label: 'Drop Down', 
    operators: [
      {'id': 'equals', 'label' : 'Equals'},
      {'id': 'notequals', 'label' : 'Not equals'}
    ]
  },
]

export const ViewTypeConstants = {
  ListView: { id: "listView", label: "List View" },
  PieChart: { id: "pieChartView", label: "Pie Chart View" },
  LineChart: { id: "linechartView", label: "Line Chart View" },
  BarChart: { id: "barchartView", label: "Bar Chart View" },
  TimeBarChart: { id: "timebarchartView", label: "Time Bar View" },
  CardView: { id: "cardview", label: "Card View" },
  IntervalView: { id: "intervalview", label: "Interval View" },
}

export const AgentStatusTabConstants = [
  {'id': 0 , 'label': 'Routing Profile', 'allowedWidgets': ["agentstatusview","agentstatusummaryview"]}, 
  {'id': 1 , 'label': 'Agent Status', 'allowedWidgets': ["agentstatusview","agentstatusummaryview"]}, 
  {'id': 2 , 'label': 'Contact State', 'allowedWidgets': ["agentstatusview","agentstatusummaryview"]}, 
  {'id': 3 , 'label': 'Agent Name','allowedWidgets': ["agentstatusview"]}, 
  {'id': 4 , 'label': 'Team Lead','allowedWidgets': ["agentstatusview"]}, 
  {'id': 5 , 'label': 'Organization','allowedWidgets': ["agentstatusview"]}
];

export const MarketAnalysisTabConstants = [
  {'id': 0 , 'label': 'Queue', 'allowedWidgets': []}, 
  {'id': 1 , 'label': 'Domain', 'allowedWidgets': []}, 
  {'id': 2 , 'label': 'Business Vertical', 'allowedWidgets': []}, 
  {'id': 3 , 'label': 'Planning Entity','allowedWidgets': []}, 
  {'id': 4 , 'label': 'Forecast Group','allowedWidgets': []}, 
  {'id': 5 , 'label': 'Hierarhy Level 1','allowedWidgets': []},
  {'id': 6 , 'label': 'Email Age Status','allowedWidgets': ["emailageanalysisview","emailanalysisview"]}
];

export const marketColumnItems = [
  { id:"QueueName", name: "Queue", type:'string' },
  { id:"Domain", name: "Domain", type:'string' },
  { id:"BusinessVertical", name: "Business Vertical", type:'string' },
  { id:"PlaningEntity", name: "Planning Entity", type:'string' },
  { id:"ForecastGroup", name: "Forecast Group", type:'string' },
  { id:"HierarchyL1", name: "Hierarchy Level 1", type:'string' },  
]

const marketGroupItems = [
  { id: 0, name: "Domain", value: "Domain" },
  { id: 1, name: "Business Vertical", value: "BusinessVertical" },
  { id: 2, name: "Planning Entity", value: "PlaningEntity" },
  { id: 3, name: "Forecast Group", value: "ForecastGroup" },
  { id: 4, name: "Web Country", value: "WebCountry" },
  { id: 5, name: "Hierarchy Level 1", value: "HierarchyL1" },  
];

const careDesignGroupItems = [
  { id: 0, name: "Type", value: "Type" },
  { id: 1, name: "Locale", value: "Locale" },
  { id: 2, name: "Status", value: "Status" }
]

export const AgentColumnItems = [
  {'id': 'RoutingProfile' , 'label': 'Routing Profile', 'allowedWidgets': ["agentstatusview","agentstatusummaryview"], type:'string'}, 
  {'id': 'AgentStatus' , 'label': 'Agent Status', 'allowedWidgets': ["agentstatusview","agentstatusummaryview"], type:'string'}, 
  {'id': 'ContactState' , 'label': 'Contact State', 'allowedWidgets': ["agentstatusview","agentstatusummaryview"], type:'string'}, 
  {'id': 'AgentName' , 'label': 'Agent Name','allowedWidgets': ["agentstatusview"], type:'string'} 
  // {'id': 'TeamLead' , 'label': 'Team Lead','allowedWidgets': ["agentstatusview"]}, 
  // {'id': 'Organization' , 'label': 'Organization','allowedWidgets': ["agentstatusview"]}
]
const DRTypeOptions = [
  {'value': 'Standard', 'label' : 'Standard'},
  {'value': 'TemplateEdits', 'label' : 'Template Edits'},
  {'value': 'Logo', 'label' : 'Logo'},
  {'value': 'DesignLive', 'label' : 'Design Live'}
]

const DRStatusOptions = [
{'value': 'On Hold','label' : 'On Hold'},
{'value': 'Escalated','label' : 'Escalated'},
{'value': 'In Progress','label' : 'In Progress'},
{'value': 'New','label' : 'New'},
{'value': 'New Revision','label' : 'New Revision'},
{'value': 'Pause','label' : 'Pause'}]

const localeOptions =[
  {'value': 'en-US', 'label' : 'en-US'},
  {'value': 'es-US', 'label' : 'es-US'},
  {'value': 'en-CA', 'label' : 'en-CA'},
  {'value': 'fr-CA', 'label' : 'fr-CA'},
  {'value': 'en-AU', 'label' : 'en-AU'},
  {'value': 'en-NZ', 'label' : 'en-NZ'},
  {'value': 'en-SG', 'label' : 'en-SG'},
  {'value': 'en-GB', 'label' : 'en-GB'},
  {'value': 'en-IE', 'label' : 'en-IE'},
  {'value': 'sv-SE', 'label' : 'sv-SE'},
  {'value': 'da-DK', 'label' : 'da-DK'},
  {'value': 'fi-FI', 'label' : 'fi-FI'},
  {'value': 'fr-FR', 'label' : 'fr-FR'},
  {'value': 'es-ES', 'label' : 'es-ES'},
  {'value': 'it-IT', 'label' : 'it-IT'},
  {'value': 'pt-PT', 'label' : 'pt-PT'},
  {'value': 'de-DE', 'label' : 'de-DE'},
  {'value': 'de-CH', 'label' : 'de-CH'},
  {'value': 'fr-CH', 'label' : 'fr-CH'},
  {'value': 'it-CH', 'label' : 'it-CH'},
  {'value': 'de-AT', 'label' : 'de-AT'},
  {'value': 'nl-NL', 'label' : 'nl-NL'},
  {'value': 'nl-BE', 'label' : 'nl-BE'},
  {'value': 'fr-BE', 'label' : 'fr-BE'}
]

export const careDesignFilterItems = [
  { id:"Type", name: "Type", type:'dropdown', dataSource:DRTypeOptions },
  { id:"Status", name: "Status", type:'dropdown', dataSource:DRStatusOptions },
  { id:"Locale", name: "Locale", type:'dropdown', dataSource: localeOptions },
  { id:"RequestName", name: "Design Request Name", type:'string' },
  { id:"OriginalQueue", name: "Original Queue", type:'string' },
  { id:"UserFullName", name: "Request Owner", type:'string' },
  { id:"HoursBeforeDeadline", name: "Hours before Deadline", type:'number' },
  { id:"PauseReson", name: "Pause Reason", type:'string' },
  { id:"RescheduleReason", name: "Reschedule Reason", type:'string' }
]

// Filter
export const FilterMatchMode = {
  STARTS_WITH : 'startsWith',
  CONTAINS : 'contains',
  NOT_CONTAINS : 'notContains',
  ENDS_WITH : 'endsWith',
  EQUALS : 'equals',
  NOT_EQUALS : 'notEquals',
  IN : 'in',
  LESS_THAN : 'lt',
  LESS_THAN_OR_EQUAL_TO : 'lte',
  GREATER_THAN : 'gt',
  GREATER_THAN_OR_EQUAL_TO : 'gte',
  BETWEEN : 'between',
  DATE_IS : 'dateIs',
  DATE_IS_NOT : 'dateIsNot',
  DATE_BEFORE : 'dateBefore',
  DATE_AFTER : 'dateAfter',
  CUSTOM : 'custom',
  LENIENT : 'lenient'
}

export const excludedWidgetComponentColumns = [
  "OnHoldTimestamp"
]

export const WidgetComponentConstants = [
  {
    widgetId: FilterModuleTypeConstants.AgentStatus,
    isDefaultAdvanceFilter: false,
    filterComponent: FilterOption,
    advanceFilterOptions: AgentColumnItems,
    items: [
      {
        id: WidgetViewItemConstants.AgentStatusView,
        title: "Agent Status",
        description: "Agent status same content",
        component: AgentStatus,
        permission: Privileges.AgentStatusView,
        viewTypes: [
          {
            id: ViewTypeConstants.ListView.id,
            imgSrc: "table.png",
            lable: ViewTypeConstants.ListView.label,
            viewCompoent: AgentStatusList,
            isHideDefaultGrouping: true,
            enableSelectedColumns: true,
            enableConditionalFormatting: true
          },
        ],
        defaultColumns: [
          { id: "FullName", label: "Full name", type:'string'},
          //{ id: "ActivityName", label: "Schedule", type:'string'},
          { id: "Username", label: "Username" , type:'string'},
          { id: "RoutingProfile", label: "Routing Profile", type:'string'},
          { id: "AgentStatus", label: "Agent Status", type:'string' },
          { id: "ContactState", label: "Contact State", type:'string' },
          { id: "PreviousAgentStatus", label: "Previous Status", type:'string' },
          { id: "Capacity", label: "WorkLoad", type:'string' },
          { id: "CapacityPercentage", label: "Capacity" ,min:0, max: 100, type:'number', addOnAfterText: '%'},
          { id: "StatusStartTimestamp", label: "Status time" , type:'time', addOnAfterText: 'In Minutes'},
          { id: "OnHoldTimestamp", label: "OnHold time" , type:'time', addOnAfterText: 'In Seconds'}
        ],
      },
      {
        id: WidgetViewItemConstants.AgentStatusSummaryView,
        title: "Agent Status Summary",
        component: AgentStatusSummary,
        permission: Privileges.AgentStatusSummaryView,
        description: "View state wise agent count",
        viewTypes: [
          {
            id: ViewTypeConstants.ListView.id,
            imgSrc: "table.png",
            lable: ViewTypeConstants.ListView.label,
            viewCompoent: AgentStatusList,
            isHideDefaultGrouping: true,
            enableSelectedColumns: true,
            enableConditionalFormatting: true
          },
          {
            id: ViewTypeConstants.PieChart.id,
            imgSrc: "pie-graph.png",
            lable: ViewTypeConstants.PieChart.label,
            viewCompoent: PieChart,
            enableSelectedColumns: false,
            enableConditionalFormatting: true
          },
          // {
          //     id:ViewTypeConstants.BarChart.id,
          //     imgSrc:'bar-hr-graph.png',
          //     lable:ViewTypeConstants.BarChart.label,
          //     viewCompoent: BarChart
          // }
        ],
        defaultColumns: [
          { id: "AgentStatus", label: "Status" , type:'string'},
          { id: "AgentCount", label: "Agent Count" , type:'number'},
          { id: "ContactState", label: "Contact State", type:'string' },
        ],
      },
    ],
  },
  {
    widgetId: FilterModuleTypeConstants.Market,
    isDefaultAdvanceFilter: false,
    filterComponent: EmailChatFilterOption,
    advanceFilterOptions: marketColumnItems,
    items: [
      {
        id: WidgetViewItemConstants.MarketAnalysisView,
        title: "Phone Analysis",
        description: "View In queue, Contacts and Service Level",
        component: AgentMarket,
        matchMode : FilterMatchMode.LENIENT,
        permission: Privileges.MarketAnalysisView,
        viewTypes: [
          {
            id: ViewTypeConstants.ListView.id,
            imgSrc: "table.png",
            lable: ViewTypeConstants.ListView.label,
            viewCompoent: MarketList,
            isHideDefaultGrouping: false,
            groupItems: marketGroupItems,
            selectedGroupItem: "Domain",
            enableSelectedColumns: true,
            enableConditionalFormatting: true
          },
          {
            id: ViewTypeConstants.CardView.id,
            imgSrc: "card.png",
            lable: ViewTypeConstants.CardView.label,
            viewCompoent: MarketCard,
            isHideDefaultGrouping: false,
            groupItems: marketGroupItems,
            selectedGroupItem: "Domain",
            enableSelectedColumns: false,
            enableConditionalFormatting: true
          }
        ],
        defaultColumns: [
          { id: "QueueName", label: "Domain", isFixed: true , type:'string'},
          { id: "timeZone", label: "Timezone" , type:'string'},
          { id: "CONTACTS_IN_QUEUE", label: "Queue" , type:'number'},
          { id: "CONTACTS_HANDLED", label: "Handled", type:'number' },
          { id: "CONTACTS_ABANDONED", label: "Abandoned", type:'number' },
          { id: "CONTACTS_QUEUED", label: "Offered", type:'number' },
          { id: "ABANDONED_RATE", label: "Aband. Rate",min:0, max: 100 , type:'number', addOnAfterText: '%'},
          { id: "OLDEST_CONTACT_AGE", label: "QTime" , type:'number', addOnAfterText: 'In seconds'},
          { id: "SERVICE_LEVEL", label: "SL" ,min:0, max: 100, type:'number', addOnAfterText: '%'},
        ]
      },
      {
        id: WidgetViewItemConstants.EmailAnalysisView,
        showHistoryData: false,
        title: "Email Analysis",
        description: "View emails Hourly Bucketisation",
        component: EmailAnalysis,
        matchMode : FilterMatchMode.LENIENT,
        permission: Privileges.EmailAnalysisView,
        viewTypes: [
          {
            id: ViewTypeConstants.ListView.id,
            imgSrc: "table.png",
            lable: ViewTypeConstants.ListView.label,
            viewCompoent: EmailAnalysisList,
            isHideDefaultGrouping: false,
            groupItems: marketGroupItems,
            subLevelGroupItems: [
              { id: 0, name: "Queue Name", value: "QueueName" },
              { id: 1, name: "Email Status", value: "AgeStatus" },
              { id: 2, name: "Agent", value: "AgentName" }],
            selectedGroupItem: "Domain",
            selectedSubLevelGroups: ["QueueName",""],
            enableSelectedColumns: true,
            enableConditionalFormatting: true
          },
          {
            id: ViewTypeConstants.CardView.id,
            imgSrc: "card.png",
            lable: ViewTypeConstants.CardView.label,
            viewCompoent: EmailAgeCard,
            isHideDefaultGrouping: false,
            groupItems: marketGroupItems,
            subLevelGroupItems: [
              { id: 0, name: "Queue Name", value: "QueueName" },
              { id: 1, name: "Email Status", value: "AgeStatus" },
              { id: 2, name: "Agent", value: "AgentName" }],
            selectedGroupItem: "Domain",
            selectedSubLevelGroups: ["QueueName",""],
            enableSelectedColumns: false,
            enableConditionalFormatting: true
          }
        ],
        defaultColumns: [
          { id: "Name", label: "Domain", isFixed: true , type:'string'},
          { id: "<8", label: "<8" , type:'number'},
          { id: "8-16", label: "8-16" , type:'number'},
          { id: "16-22", label: "16-22", type:'number'},
          { id: "22-24", label: "22-24", type:'number' },
          { id: "24-48", label: "24-48", type:'number' },
          { id: "48-72", label: "48-72", type:'number' },
          { id: "72-120", label: "72-120", type:'number' },
          { id: "120-240", label: "120-140", type:'number' },
          { id: ">240", label: ">240", type:'number' },
          { id: "Total", label: "Total" , type:'number'}
        ],
      },
      {
        id: WidgetViewItemConstants.ChatAnalysisView,
        showHistoryData: true,
        title: 'Chat Analysis',
        description: 'View In Abandoned,Handled, Service Level..etc',
        component : ChatStatusAnalysis,
        matchMode : FilterMatchMode.LENIENT,
        permission: Privileges.ChatAnalysisView,
        viewTypes:[
          {
            id: ViewTypeConstants.ListView.id,
            imgSrc: "table.png",
            lable: ViewTypeConstants.ListView.label,
            viewCompoent: ChatStatusList,
            isHideDefaultGrouping: false,
            groupItems: marketGroupItems,
            selectedGroupItem: "Domain",
            enableSelectedColumns: true,
            enableConditionalFormatting: true,
            selectedSubLevelGroups: ["QueueName",""]
          },
          {
            id: ViewTypeConstants.CardView.id,
            imgSrc: "card.png",
            lable: ViewTypeConstants.CardView.label,
            viewCompoent: ChatAnalysisCard,
            isHideDefaultGrouping: false,
            groupItems: marketGroupItems,
            selectedGroupItem: "Domain",
            enableSelectedColumns: false,
            enableConditionalFormatting: true,
            selectedSubLevelGroups: ["QueueName",""]
          }
        ],
        defaultColumns: [
          { id:"QueueName", label: "Domain", isFixed: true, type:'string'},
          { id:"timeZone", label: "Timezone" , type:'string'},
          { id:"Missed", label: "Abandoned", type:'number'},
          { id:"Completed", label: "Handled", type:'number'},
          { id:"Offered", label: "Offered", type:'number'},
          { id:"Waiting", label: "In Queue", type:'number'},
          { id:"In_Progress", label: "In Progress", type:'number'},
          { id:"AWT", label: "ASA" , type:'number'},
          { id:"ACD", label: "AHT" , type:'number'},
          { id:"HandledInSL", label: "Handled in SL" , type:'number'},
          { id:"AbandonedInSL", label: "Abandoned in SL" , type:'number'},
          { id:"AbandonedRate", label: "Aband. Rate",min:0, max: 100 , type:'number', addOnAfterText: '%'},
          { id:"ServiceLevel", label: "SL" ,min:0, max: 100, type:'number', addOnAfterText: '%'},
          { id: "QTime", label: "QTime" , type:'number', addOnAfterText: 'In seconds'},
        ]
      },
      {
        id: WidgetViewItemConstants.ChatAgeAnalysisView,
        title: "Chat Age Analysis",
        description: "View new chats Hourly Bucketisation",
        component: ChatStatusAnalysis,
        matchMode : FilterMatchMode.LENIENT,
        permission: Privileges.ChatAgeAnalysisView,
        viewTypes: [
          {
            id: ViewTypeConstants.ListView.id,
            imgSrc: "table.png",
            lable: ViewTypeConstants.ListView.label,
            viewCompoent: ChatCaseAgeList,
            isHideDefaultGrouping: false,
            groupItems: marketGroupItems,
            selectedGroupItem: "Domain",
            enableSelectedColumns: true,
            enableConditionalFormatting: true,
            selectedSubLevelGroups: ["QueueName",""]
          },
           {
            id: ViewTypeConstants.CardView.id,
            imgSrc: "card.png",
            lable: ViewTypeConstants.CardView.label,
            viewCompoent: ChatAgeCard,
            isHideDefaultGrouping: false,
            groupItems: marketGroupItems,
            selectedGroupItem: "Domain",
            enableSelectedColumns: false,
            enableConditionalFormatting: true,
            selectedSubLevelGroups: ["QueueName",""]
          }
        ],
        defaultColumns: [
          { id: "QueueName", label: "Domain", isFixed: true, type:'string' },
          { id: "0-30", label: "0-30, type:'number'" },
          { id: "30-60", label: "30-60", type:'number' },
          { id: "60-90", label: "60-90" , type:'number'},
          { id: "90-120", label: "90-120" , type:'number'},
          { id: ">120", label: ">120" , type:'number'},
          { id: "Total", label: "Total" , type:'number'}
        ],
      },
      {
        id: WidgetViewItemConstants.VisitorChatStatView,
        title: 'Visitor Chat Stats',
        description: 'View Visitor Statstics..etc',
        component : ChatStatusAnalysis,
        matchMode : FilterMatchMode.LENIENT,
        permission: Privileges.VisitorChatStats,
        viewTypes:[
          {
            id: ViewTypeConstants.ListView.id,
            imgSrc: "table.png",
            lable: ViewTypeConstants.ListView.label,
            viewCompoent: VisitorChatStatsList,
            isHideDefaultGrouping: false,
            groupItems: [...marketGroupItems, { id: 5, name: "Category", value: "Category" }],
            selectedGroupItem: "Domain",
            enableSelectedColumns: true,
            enableConditionalFormatting: true,
            selectedSubLevelGroups: ["QueueName",""]
          },
        ],
        defaultColumns: [
          { id:"QueueName", label: "Developer", isFixed: true, type:'string'},
          { id:"timeZone", label: "Timezone" , type:'string'},         
          { id:"OMC", label: "Agent Message Count" , type:'number'},
          { id:"VMC", label: "Visitor Message Count" , type:'number'},
          { id:"ARTO", label: "Agent Average Response Time" , type:'number'},
          { id:"ARTV", label: "Visitor Average Response Time" , type:'number'},
          { id:"MRTO", label: "Agent Max Response Time", type:'number'},
          { id:"MRTV", label: "Visitor Max Response Time" ,type:'number'},         
        ]
      },
    ],
  },
  {
    widgetId: FilterModuleTypeConstants.CareDesign,
    filterComponent: CareDesignFilter,
    isDisableFilterSwitch: true,
    isDefaultAdvanceFilter: true,
    advanceFilterOptions: careDesignFilterItems,
    items: [
      {
        id: WidgetViewItemConstants.OpenTicketStatusView,
        title: "Open Ticket Status",
        description: "View ticket status",
        component: OpenTicketStatus,
        matchMode : FilterMatchMode.LENIENT,
        permission: Privileges.DesignRequestStatus,
        viewTypes: [
          {
            id: ViewTypeConstants.ListView.id,
            imgSrc: "table.png",
            lable: ViewTypeConstants.ListView.label,
            viewCompoent: OpenTicketList,
            isHideDefaultGrouping: false,
            groupItems: careDesignGroupItems,
            selectedGroupItem: "Type",
            subLevelGroupItems: careDesignGroupItems,
            selectedSubLevelGroups: ["Locale","Status"],
            enableSelectedColumns: true,
            enableConditionalFormatting: true
          },
          {
            id: ViewTypeConstants.IntervalView.id,
            imgSrc: "interval.png",
            lable: ViewTypeConstants.IntervalView.label,
            viewCompoent: IntervalList,
            isHideDefaultGrouping: false,
            groupItems: careDesignGroupItems,
            selectedGroupItem: "Type",
            subLevelGroupItems: careDesignGroupItems,
            selectedSubLevelGroups: ["Locale","Status"],
            enableSelectedColumns: false,
            enableConditionalFormatting: false
          }
        ],
        defaultColumns: [
          { id: "Name", label: "Name", isFixed: true, type:'string' },
          { id: "Missed", label: "Missed", type:'number'},
          { id: "<3", label: "<3", type:'number' },
          { id: "<6", label: "<6" , type:'number'},
          { id: "<9", label: "<9" , type:'number'},
          { id: "<12", label: "<12" , type:'number'},
          { id: "<15", label: "<15" , type:'number'},
          { id: "<18", label: "<18" , type:'number'},
          { id: ">18", label: ">18" , type:'number'},
          { id: "Total", label: "Total" , type:'number'}
        ]
      }
    ]
  }
]

export const HierarchyLevel1 = [{id: 0,value:"CUSTOMER"}, {id:1, value: "SUPPORT"}]
export const EmailAgeStatuses = [{id: 0, value:'New In Queue'}, {id:1, value: 'New Not In Queue'},
    {id: 2, value:'In Progress'}, {id: 3, value:'Waiting on Customer'}, {id:4, value: 'Response Received'}]

export const ModuleConstants = {
  RealTime: 1,
};

export const apiUrl = "admin/";
export const getWidgetTypesUrl = apiUrl + "widget/getWidgetTypes";
export const getWidgetsUrl = apiUrl + "widget/getWidgets";
export const saveWidgetsUrl = apiUrl + "widget/saveWidgets";
export const saveRTDashboardUrl = apiUrl + "widget/saveRTDashboard";
export const getRTDashboardUrl = apiUrl + "widget/getAllDashboards";
export const deleteRTDashboardUrl = apiUrl + "widget/deleteRTDashboard";
export const isFavouriteDashboardUrl = apiUrl + "widget/isFavouriteDashboard";
export const getRolesUrl = apiUrl + "role/getall";
export const shareDashboardUrl = apiUrl + "widget/shareDashboard";
export const GetshareDashboardUrl = apiUrl + "widget/getShareDashboard";
export const getUsersDashboardShareRoleUrl =
  apiUrl + "widget/getUsersDashboardShareRole";
export const getShareDashboardCollaboratorsUrl =
  apiUrl + "widget/getShareDashboardCollaborators";
export const deleteShareDashboardCollaboratorUrl =
  apiUrl + "widget/deleteShareDashboardCollaborator";
export const updateSharedCollaboratorPermissionUrl =
  apiUrl + "widget/updateSharedCollaboratorPermission";

  export const saveConditionalFormatsUrl = apiUrl + "RealTimeDashboard/saveConditionalFormats";
  export const deleteConditionalFormatUrl = apiUrl + "RealTimeDashboard/deleteConditionalFormat";
  export const getConditionalFormatingUrl = apiUrl + "RealTimeDashboard/getConditionalFormating";
  export const getRulesByConditionalFormatIdUrl = apiUrl + "RealTimeDashboard/getRulesByConditionalFormatId";
  export const getFilterSettingsUrl = apiUrl + "dashboardfilter/getFilterSettings";
  export const saveFilterSettingUrl = apiUrl + "dashboardfilter/saveFilterSettings";
  export const deleteFilterSettingUrl = apiUrl + "dashboardfilter/deleteFilterSettings";
  export const getTeamLeadNOrgUrl = apiUrl + "User/GetTeamLeadDetails";
  

const RTDServiceUrl = process.env.REACT_APP_RTD_URL !== undefined
    ? process.env.REACT_APP_RTD_URL : "";
export const getRoutingProfileUrl = RTDServiceUrl + "api/getRoutingProfiles";
export const getAgentStausUrl = RTDServiceUrl + "api/getAgentStauses";
export const getContactStatusUrl = RTDServiceUrl + "api/getContactStatuses";
export const getAgentNamesUrl = RTDServiceUrl + "api/getAgentNames";
export const getStaffingQueuesUrl = RTDServiceUrl + "api/getStaffingQueues";
export const getMarketsAndQueuesUrl = RTDServiceUrl + "api/getMarketsAndQueues";
export const getQueueMappingsUrl = RTDServiceUrl + "api/getqueuemappings";

export const timeFormat = "HH:mm:ss"
export const dateTimeFormat = "DD-MM-YYYY HH:mm:ss"
export const defaultFormatingName = 'Default Formating'
export const StatusStartTimestamp = 'StatusStartTimestamp'
export const OnHoldTimestamp = 'OnHoldTimestamp'
export const QTime = 'QTime'
export const AgentName = 'AgentName'
export const Domain = "Domain"
export const ConditionOprators = { OR : "OR", AND: "AND"}
export const NonProductiveStatus = ["Break", "Lunch", "Meeting/Admin","Personal","System Issues", "Training"]
export const EmailAnalysisCategories = ["<8","8-16","16-22","22-24","24-48","48-72","72-120","120-240",">240"]
export const AgentWidgetsConstant = [WidgetViewItemConstants.AgentStatusView,WidgetViewItemConstants.AgentStatusSummaryView]

export const HourFilters = {
  PREVIOUS_X_HOUR : 'Previous',
  SPECIFIC_HOUR : 'Specific',
  MIDNIGHT: 'Now'
}
export const DefaultWidgetPosition = {
  x: 0,
  y: 0,
  w: 16,
  h: 12,
}
export const ChatViewItemsContstant = [WidgetViewItemConstants.ChatAnalysisView,WidgetViewItemConstants.ChatAgeAnalysisView,WidgetViewItemConstants.VisitorChatStatView]

export const IntervalWidgetType = {
  MISSED : 'Missed',
  INCOMING : 'Incoming'  
};
